import React from 'react';

import backgroundImage from '../../assets/images/background-frontpage-hres.jpg';
import { styled } from '@mui/system';
import { useForm, ValidationError } from '@formspree/react';


const RootDiv = styled('div')({
    position: 'relative',
    width: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh'
});

const BrownDiv = styled('div')({
    zIndex: 1,
    background: 'linear-gradient(to right, #949188, #535951)'
});

const SubmitButton = styled('button')({
    background: 'linear-gradient(to right, #949188, #535951)',
    color: 'white',
    borderColor: 'unset',
    padding: '5px',
    ':hover': {
        opacity: 0.9
    }
});

const ContactPage = () => {
    const [state, handleSubmit] = useForm("xbjnjydw");
    if (state.succeeded) {
        return <p>I will get back to you, as soon as possible. Thank you for contacting me!</p>;
    }
    return (
        <RootDiv>
            <div className="container-fluid">
                <BrownDiv>
                    <div className="row">
                        <div className="col d-flex align-items-center justify-content-center"
                             style={{minHeight: '100px'}}>
                            <h1 className="text-white">Contact</h1>
                        </div>
                    </div>
                </BrownDiv>
            </div>
            <div className="container p-5">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <h2 className="pb-md-5 pb-2">Via Mail</h2>
                        <a href="mailto:mail@bjornwouters.com">
                            <p>mail@bjornwouters.com</p>
                        </a>
                    </div>
                    <div className="col-md-6 text-center">
                        <h2>Via Forms</h2>
                        <div className="text-start">
                            <form onSubmit={handleSubmit}>
                                <div className="row pt-5">
                                    <label htmlFor="email">
                                        Email Address
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                    />
                                    <ValidationError
                                        prefix="Email"
                                        field="email"
                                        errors={state.errors}
                                    />
                                </div>
                                <div className="row pt-3">
                                    <label htmlFor="message">
                                        Question
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                    />
                                    <ValidationError
                                        prefix="Message"
                                        field="message"
                                        errors={state.errors}
                                    />
                                </div>
                                <div className="row pt-5 w-25">
                                    <SubmitButton type="submit" disabled={state.submitting}>
                                        Submit
                                    </SubmitButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </RootDiv>
    );
};

export default ContactPage;
