import React from 'react';
import SpecialtyContainer from '../small/SpecialtyContainer'; // import the SpecialtyContainer component

import backgroundImage from '../../assets/images/background-frontpage-hres.jpg';
import dataAnalysisLogo from '../../assets/images/data-analysis-logo.png';
import infrastructureLogo from '../../assets/images/infrastructure-logo.png';
import dataModellingLogo from '../../assets/images/data-model-logo.png';
import dataProcessingLogo from '../../assets/images/data-processing-logo.png';
import logoLarge from '../../assets/images/logo-500.png';
import websiteContent from '../../assets/text/website-content.json';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
    position: 'relative',
    width: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh'
});

const PageTitle = styled('h1')({
    fontSize: 'calc(1.5rem + 1.5vw)',
    paddingTop: '10%',
    textAlign: 'center'
});

const PageUnderTitle = styled('h3')({
    paddingTop: '50px',
    textAlign: 'center'
});

const LogoLarge = styled('img')({
    position: 'absolute',
    left: '30px',
    top: '15px',
    width: '150px',

    // Media query for screen widths less than 1000px
    '@media (max-width: 1000px)': {
        width: '75px', // half of 150px
    }
});

const WelcomePage = () => {
    return (
        <RootDiv>
            <LogoLarge src={logoLarge} alt="logo"/>
            <div className="container-fluid">
                <div className="row align-content-center">
                    <div className="col">
                        <PageTitle>Björn Wouters</PageTitle>
                        <PageUnderTitle>Igniting business potential through the power of data.</PageUnderTitle>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <SpecialtyContainer targetId={"analysis"} title={"Data analysis"} text={websiteContent.welcomePage.containerText.dataAnalysis} logo={dataAnalysisLogo} />
                    <SpecialtyContainer targetId={"infrastructure"} title={"Infrastructure"} text={websiteContent.welcomePage.containerText.infrastructure} logo={infrastructureLogo} />
                    <SpecialtyContainer targetId={"modelling"} title={"Data modelling"} text={websiteContent.welcomePage.containerText.dataModelling} logo={dataModellingLogo} />
                    <SpecialtyContainer targetId={"processing"} title={"Data processing"} text={websiteContent.welcomePage.containerText.dataProcessing} logo={dataProcessingLogo} />
                </div>
            </div>
        </RootDiv>
    );
};

export default WelcomePage;
