import React from 'react';
import WelcomePage from './components/pages/WelcomePage'; // import the FrontPage component
import AboutMePage from './components/pages/AboutMePage'; // import the AboutMePage component
import SpecialtyPage from './components/pages/SpecialtyPage'; // import the SpecialtyPage component
import ContactPage from './components/pages/ContactPage'; // import the ContactPage component

import websiteContent from './assets/text/website-content.json';
import dataAnalysisLogo from './assets/images/data-analysis-logo.png';
import dataProcessingLogo from './assets/images/data-processing-logo.png';
import dataModelLogo from './assets/images/data-model-logo.png';
import InfrastructureLogo from './assets/images/infrastructure-logo.png';

import './styles/base.css';

function App() {
  return (
    <div className="App base-fonts">
        <WelcomePage />
        <AboutMePage />
        <SpecialtyPage
            id={'analysis'}
            title="Data Analysis"
            logoSrc={dataAnalysisLogo}
            text={websiteContent.specialtyPage.dataAnalysis.text}
            example1Title={websiteContent.specialtyPage.dataAnalysis.example1.title}
            example1Text={websiteContent.specialtyPage.dataAnalysis.example1.text}
            example2Title={websiteContent.specialtyPage.dataAnalysis.example2.title}
            example2Text={websiteContent.specialtyPage.dataAnalysis.example2.text}
            example3Title={websiteContent.specialtyPage.dataAnalysis.example3.title}
            example3Text={websiteContent.specialtyPage.dataAnalysis.example3.text}
            outro={websiteContent.specialtyPage.dataAnalysis.outro}
        />
        <SpecialtyPage
            id={'infrastructure'}
            title="Infrastructure"
            logoSrc={InfrastructureLogo}
            text={websiteContent.specialtyPage.infrastructure.text}
            example1Title={websiteContent.specialtyPage.infrastructure.example1.title}
            example1Text={websiteContent.specialtyPage.infrastructure.example1.text}
            example2Title={websiteContent.specialtyPage.infrastructure.example2.title}
            example2Text={websiteContent.specialtyPage.infrastructure.example2.text}
            example3Title={websiteContent.specialtyPage.infrastructure.example3.title}
            example3Text={websiteContent.specialtyPage.infrastructure.example3.text}
            outro={websiteContent.specialtyPage.infrastructure.outro}
        />
        <SpecialtyPage
            id={'modelling'}
            title="Data Modelling"
            logoSrc={dataModelLogo}
            text={websiteContent.specialtyPage.dataModelling.text}
            example1Title={websiteContent.specialtyPage.dataModelling.example1.title}
            example1Text={websiteContent.specialtyPage.dataModelling.example1.text}
            example2Title={websiteContent.specialtyPage.dataModelling.example2.title}
            example2Text={websiteContent.specialtyPage.dataModelling.example2.text}
            example3Title={websiteContent.specialtyPage.dataModelling.example3.title}
            example3Text={websiteContent.specialtyPage.dataModelling.example3.text}
            outro={websiteContent.specialtyPage.dataModelling.outro}
        />
        <SpecialtyPage
            id={'processing'}
            title="Data Processing"
            logoSrc={dataProcessingLogo}
            text={websiteContent.specialtyPage.dataProcessing.text}
            example1Title={websiteContent.specialtyPage.dataProcessing.example1.title}
            example1Text={websiteContent.specialtyPage.dataProcessing.example1.text}
            example2Title={websiteContent.specialtyPage.dataProcessing.example2.title}
            example2Text={websiteContent.specialtyPage.dataProcessing.example2.text}
            example3Title={websiteContent.specialtyPage.dataProcessing.example3.title}
            example3Text={websiteContent.specialtyPage.dataProcessing.example3.text}
            outro={websiteContent.specialtyPage.dataProcessing.outro}
        />
        <ContactPage/>
    </div>
  );
}

export default App;