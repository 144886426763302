import React from 'react';

import { styled } from '@mui/system';

const GetInTouchButton = styled('div')({
    cursor: 'pointer',
    padding: '10px 20px',
    background: 'linear-gradient(to right, #949188, #535951)',
    color: 'white',
    textAlign: 'center',
    borderRadius: '5px',
    marginTop: '50px',
    marginBottom: '40px',
    transition: 'background-color 1s',

    '&:hover': {
        opacity: 0.9
    }
});

interface SpecialtyPageProps {
    id: string;
    title: string;
    logoSrc: string;
    text: string;
    example1Title: string;
    example1Text: string;
    example2Title: string;
    example2Text: string;
    example3Title: string;
    example3Text: string;
    outro: string;
}

const RootDiv = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(to right, #E7EEE8 , #CCDEDB)'
});

const ContentBackground = styled('div')({
    position: 'relative',
    background: 'linear-gradient(to right, #E7EEE8 , #CCDEDB)',
    zIndex: 1
});

const SpecialtyPage: React.FC<SpecialtyPageProps>
    = ({ id, title, logoSrc, text, example1Title, example1Text, example2Title, example2Text, example3Title, example3Text, outro }) => {
    // Scroll to the bottom of the page
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    return (
        <RootDiv id={id}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <ContentBackground>
                            <h1 className="pt-5">{title}</h1>
                            <img src={logoSrc} alt="Logo"/>
                            <div className="row pt-3 justify-content-center">
                                <div className="col-md-8">
                                    <p>{text}</p>
                                    <div className="text-start pt-3">
                                        <h2 className="pb-3">Examples</h2>
                                        <h5>{example1Title}</h5>
                                        <p>{example1Text}</p>
                                        <h5>{example2Title}</h5>
                                        <p>{example2Text}</p>
                                        <h5>{example3Title}</h5>
                                        <p>{example3Text}</p>
                                        <h6 className="pt-5 text-center"><b>{outro}</b></h6>
                                        <GetInTouchButton onClick={scrollToBottom}>
                                            Get in touch
                                        </GetInTouchButton>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </ContentBackground>
                    </div>
                </div>
            </div>
        </RootDiv>
    );
};

export default SpecialtyPage;