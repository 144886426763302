import React from 'react';
import {styled} from "@mui/system";

import mePhoto from '../../assets/images/me-photo.png';
import meBackground from '../../assets/images/me-background.png';
import resumeImage from '../../assets/images/resume-image.png';

import websiteContent from '../../assets/text/website-content.json';

const RootDiv = styled('div')({
    position: 'relative',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'linear-gradient(to right, #E7EEE8 , #CCDEDB)'
});

const BrownDiv = styled('div')({
    position: 'relative',
    zIndex: 1,
    height: '300px',
    width: '100%',
    background: 'linear-gradient(to right, #949188, #535951)'
});

const PageTitle = styled('h1')({
    paddingTop: '10%',
    fontSize: '4vw',
    textAlign: 'center'
});

const MeImg = styled('img')({
    position: 'relative',
    zIndex: '1'
});

const MeBackgroundImg = styled('img')({
    left: '10%',
    top: 0,
    position: 'absolute',
    zIndex: '0'
});

const ContentBackground = styled('div')({
    position: 'absolute',
    zIndex: '2',
    borderRadius: '25px',
    background: 'linear-gradient(to right, #949188, #535951)',

    '@media (max-width: 991px)': {
        position: 'relative',
    }
});
const ContentBackgroundShadow = styled('div')({
    position: 'absolute',
    zIndex: '-1',
    top: '8px',
    left: '8px',
    borderRadius: '25px',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, #E7EEE8 , #CCDEDB)'
});

const SepLine = styled('div')({
    marginLeft: '20%',
    height: '4px',
    width: '60%',
    background: 'linear-gradient(to right, #949188, #535951)',
    marginBottom: '25px'
});

const BottomDiv = styled('div')({
    paddingTop: '50px'
});

const AboutMeTitle = styled('h3')({
    paddingTop: '40px',
    color: 'white'
});
const AboutMeMessage = styled('p')({
    color: 'white'
});



const AboutMePage = () => {
    return (
        <RootDiv>
            <div className="container-fluid">
                <div className="row align-content-start">
                    <div className="col-3">
                        <PageTitle>About Me</PageTitle>
                    </div>
                </div>
                <div className="row justify-content-end pt-5">
                    <div className="col-lg-4">
                        <MeImg src={mePhoto} alt="me" width="80%"/>
                        <MeBackgroundImg src={meBackground} alt="me" width="50%"/>
                    </div>
                    <div className="col-lg-6 text-center">
                        <ContentBackground>
                            <ContentBackgroundShadow/>
                            <h1 className="pt-4">Resume</h1>
                            <SepLine/>
                            <div className="row">
                                <div className="col-6">
                                    <h4>Bsc. Bio-informatics</h4>
                                </div>
                                <div className="col-6">
                                    <h4>Msc. Bio-informatics &
                                        Systems biology</h4>
                                </div>
                            </div>
                            <div className="pt-5"/>
                            <SepLine/>
                            <div className="row text-start justify-content-center">
                                <div className="col-6">
                                    <h4 className="pt-2"><b>Professional experiences</b></h4>
                                    <hr/>
                                    <h5 className="pt-3">Software Engineering</h5>
                                    <h5 className="pt-3">Data Engineering</h5>
                                    <h5 className="pt-3">Data Architecture</h5>
                                    <h5 className="pt-3">Entrepreneur</h5>
                                </div>
                                <div className="col-4 pt-2">
                                <img src={resumeImage} alt="resumeImg" width="100%"/>
                                </div>
                            </div>
                            <BottomDiv/>
                        </ContentBackground>
                    </div>
                </div>
            </div>
            <BrownDiv>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <AboutMeTitle>On a personal note</AboutMeTitle>
                            <AboutMeMessage>{websiteContent.aboutMePage.personalMessage}</AboutMeMessage>
                        </div>
                    </div>
                </div>
            </BrownDiv>
        </RootDiv>
    );
};

export default AboutMePage;