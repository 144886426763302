import React from 'react';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
    marginTop: '25%',
    borderRadius: '25px',
    padding: '5px',
    background: 'linear-gradient(to right, #E7EEE8 , #CCDEDB)',
    '@media (max-width: 1000px)': {
        borderRadius: '0',
    }
});

const ContainerTitle = styled('h4')({
    textAlign: 'center'
});

interface SpecialtyContainerProps {
    title: string;
    text: string;
    logo: string;
    targetId: string; // ID of the target component to scroll to
}

const ScrollButton = styled('div')({
    cursor: 'pointer',
    padding: '10px 10px',
    background: 'linear-gradient(to right, #949188, #535951)',
    color: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    margin: '10px 20px',
    transition: 'background-color 1s',

    '&:hover': {
        opacity: 0.9
    }
});

const SpecialtyContainer: React.FC<SpecialtyContainerProps> = ({ targetId, title, text, logo }) => {
    const scrollToTarget = () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="col-lg-6 col-xl-2">
            <RootDiv>
                <div className="row">
                    <div className="col-4">
                        <img src={logo} alt={`${title} logo`} width="100%" />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                        <ContainerTitle className="mx-auto">{title}</ContainerTitle>
                    </div>
                </div>
                <div className="row justify-content-center pt-3">
                    <div className="col-10">
                        <p>{text}</p>
                    </div>
                </div>
                <ScrollButton onClick={scrollToTarget}>
                    Read more
                </ScrollButton>
            </RootDiv>
        </div>
    );
};

// Use React.memo if props rarely change and performance is a concern
export default React.memo(SpecialtyContainer);